import React from 'react'

function RentalSection() {
  return (
    <div className="popout_body__content_container" id="unit-rental-section">
      <h2>Rental Details</h2>
      <div className="rental-content">

      </div>
    </div>
  )
}

export default RentalSection 