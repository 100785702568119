import * as React from "react";

const RentedIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    id="Capa_1"
    width="800"
    height="800"
    fill="#000"
    version="1.1"
    viewBox="0 0 315.752 315.752"
  >
    <path d="M263.89 8.743a5.503 5.503 0 0 0-5.503 5.503v257.607c0 .854.199 1.696.581 2.461l14.829 29.654a5.503 5.503 0 0 0 9.844 0l14.828-29.654a5.5 5.5 0 0 0 .581-2.461V14.246a5.503 5.503 0 0 0-5.503-5.503zm24.154 261.809-9.325 18.649-9.326-18.649V19.749h18.651zM304.746 48.037v87.789a5.503 5.503 0 0 0 11.006 0V48.037a5.503 5.503 0 0 0-11.006 0M186.526 164.64H47.204a5.504 5.504 0 0 0 0 11.006h139.322a5.502 5.502 0 1 0 0-11.006M186.526 246.068H47.204a5.503 5.503 0 0 0 0 11.006h139.322a5.503 5.503 0 0 0 0-11.006M186.526 129.295H47.204a5.503 5.503 0 0 0 0 11.006h139.322a5.503 5.503 0 0 0 0-11.006M47.204 104.959h82.709a5.504 5.504 0 0 0 0-11.006H47.204a5.502 5.502 0 1 0 0 11.006M47.204 69.615h82.709a5.502 5.502 0 1 0 0-11.006H47.204a5.504 5.504 0 0 0 0 11.006"></path>
    <path d="M5.488 306.568h222.754a5.49 5.49 0 0 0 5.488-5.488V87.843l-.001-.022a6 6 0 0 0-.027-.525 5 5 0 0 0-.059-.396c-.008-.045-.012-.091-.02-.136a6 6 0 0 0-.115-.459l-.016-.061a7 7 0 0 0-.16-.446q-.011-.029-.021-.058a5 5 0 0 0-.191-.402q-.02-.042-.04-.084-.098-.177-.208-.349-.034-.057-.069-.113a5 5 0 0 0-.223-.301q-.048-.066-.098-.132a5 5 0 0 0-.303-.333c-.02-.021-.036-.043-.057-.063L158.948 10.79a6 6 0 0 0-.397-.359q-.055-.042-.111-.083a6 6 0 0 0-.32-.237q-.05-.03-.1-.061a6 6 0 0 0-.361-.217q-.034-.017-.069-.033a5 5 0 0 0-.417-.197l-.047-.018a6 6 0 0 0-.457-.164l-.053-.013a5 5 0 0 0-.467-.118q-.066-.01-.132-.02c-.133-.023-.266-.047-.4-.06a5 5 0 0 0-.548-.028H5.488A5.49 5.49 0 0 0 0 14.671V301.08a5.49 5.49 0 0 0 5.488 5.488M214.995 82.357l-54.434.006-.003-54.441zM10.976 20.16h138.605l.004 67.692a5.49 5.49 0 0 0 5.488 5.487l67.682-.008v202.261H10.976z"></path>
  </svg>
);

export default RentedIcon