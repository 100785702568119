import React, { useState, useEffect, useRef } from 'react'
import './DropdownMultiselect.css'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CloseIcon from '@mui/icons-material/Close'
import httpClient from './helpers/httpClient'
import { MdOutlineDeleteSweep } from "react-icons/md"

function DropdownMultiselect({ fontStyle, listArray, state, marginTop, fontSize, setState, width, idList, index, displayValueKey, allowCreate = false, createEndpoint, createValueKey, disabled = false }) {
  const [selectedValues, setSelectedValues] = useState(state || [])
  const [searchValue, setSearchValue] = useState("")
  const [filteredList, setFilteredList] = useState(listArray)

  useEffect(() => {
    if (!listArray?.length) return;

    if (typeof listArray[0] === 'object') {
      const filtered = listArray.filter(item => 
        !selectedValues.some(selected => selected.id === item.id)
      );
      setFilteredList(filtered);
    } else {
      const filtered = listArray.filter(item => 
        !selectedValues.includes(item)
      );
      setFilteredList(filtered);
    }
  }, [listArray, selectedValues])

  useEffect(() => {
    if (state && JSON.stringify(state) !== JSON.stringify(selectedValues)) {
      setSelectedValues(state);
    }
  }, [state])

  const ref = useRef(null)

  const onClickOutside = () => {
    const dropdownList = document.querySelectorAll(`#${idList}`)

    Array.from(dropdownList).forEach(dropdown => {
      dropdown.classList.remove("active")
    })
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside && onClickOutside()
      }
    }
    document.addEventListener("click", handleClickOutside, true)

    return () => {
      document.removeEventListener("click", handleClickOutside, true)
    }
  }, [onClickOutside])

  const handleClickDropdown = (event) => {
    const dropdownList = event.currentTarget.nextSibling
    dropdownList.classList.toggle("active")

    const searchBar = document.querySelector(`#search-bar`)
    searchBar.focus()
  }

  const onclickMenuItem = (event) => {
    if (typeof listArray[0] === 'object') {
      const itemID = event.currentTarget.getAttribute("data-index")
      const selectedItem = listArray.find(item => item.id.toString() === itemID)
      if (!selectedItem) return

      setState(selectedItem, "add")
    } else {
      const item = event.currentTarget.getAttribute("data-value")
      setState(item, "add")
    }
    setSearchValue("")
  }

  const deleteMenuItem = (event) => {
    event.stopPropagation()

    if (typeof listArray[0] === 'object') {
      const itemID = event.currentTarget.getAttribute("data-item-id")
      const item = selectedValues.find(value => value.id == itemID)

      const newState = selectedValues.filter(value => value.id != itemID)
      setSelectedValues(newState)
      setState(item, "remove")
      setSearchValue("")
    }

    if (typeof listArray[0] === 'string') {
      const item = event.currentTarget.getAttribute("data-value")
      const newState = selectedValues.filter(value => value != item)
      setSelectedValues(newState)
      setState(item, "remove")
      setSearchValue("")
    }


  }

  const handleCreate = async () => {
    if (!searchValue.trim()) return;
    
    try {
      const response = await httpClient.post(
        process.env.REACT_APP_API_URL + createEndpoint,
        { [createValueKey]: searchValue }
      );
      
      const newItem = response.data;
      setFilteredList(prev => [...prev, newItem]);
      setSearchValue("");
      
      const selectedItem = newItem;
      setSelectedValues(prevArray => [...prevArray, selectedItem]);
      setState(selectedItem, "add");
    } catch (error) {
      console.error('Error creating item:', error);
    }
  };

  return (
    <div className={`dropdown__container-dropdown--multiselect tags flex--vert ${disabled ? "disabled" : ""}`} id={`dropdown__${index}`} style={{ width, marginTop }} ref={ref} key={index}>
      <span className={`input_field__input_box--text dropdown classic`} style={{ marginTop }} onClick={handleClickDropdown}>
        {
          state !== undefined && typeof listArray[0] === 'object' && (
            selectedValues.map((value, index) => {
              const displayValue = displayValueKey
                ? value[displayValueKey]
                : Object.entries(value).find(([key]) => key !== 'id')?.[1] || '';
              return (
                <p className={`dropdown-input--text_field`} onClick={deleteMenuItem} style={{ fontSize, fontStyle }} key={index} data-item-id={value.id}>
                  {displayValue}
                  <CloseIcon />
                </p>
              );
            })
          )
        }

        {
          state !== undefined && typeof listArray[0] === 'string' && (
            selectedValues.map((value, index) => {
              return (
                <p className={`dropdown-input--text_field`} onClick={deleteMenuItem} style={{ fontSize, fontStyle }} key={index} data-value={value}>
                  {value}
                  <CloseIcon />
                </p>
              );
            })
          )
        }
      </span>
      <ul className="input_field__dropdown classic" id={idList} key={index}>
        <div className='search-bar_dropdown_container'>
          <input className="search-bar dropdown" id={`search-bar`} placeholder="Search..." value={searchValue} onChange={event => setSearchValue(event.currentTarget.value)} autoComplete="off" />
          {allowCreate && searchValue.trim() && (
            <button className="create-button" onClick={handleCreate}>Create</button>
          )}
        </div>
        {
          typeof listArray[0] === 'object' && listArray[0] !== null && (
            filteredList
              .filter(item => Object.values(item).some(value => String(value).toLowerCase().includes(searchValue.toLowerCase())))
              .map((item, index) => (
                <li className='dropdown__item' key={index} data-index={item.id} style={{ fontSize }} onClick={onclickMenuItem}>
                  <div>
                    {displayValueKey ? item[displayValueKey] : 
                      Object.entries(item)
                        .filter(([key]) => key !== 'id')
                        .map(([key, value]) => value)[0]
                    }
                  </div>
                </li>
              ))
          )
        }

        {
          typeof listArray[0] === 'string' && (
            filteredList.map((item, index) => (
              <li className='dropdown__item' key={index} data-value={item} style={{ fontSize }} onClick={onclickMenuItem}>
                {item}
              </li>
            ))
          )
        }

      </ul>
    </div>
  )
}

DropdownMultiselect.defaultProps = {
  displayValueKey: null,
  marginTop: "0",
  fontStyle: "italic",
  height: "40px",
  allowCreate: false,
  createEndpoint: "",
  createValueKey: "",
  disabled: false
}

export default DropdownMultiselect