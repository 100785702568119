import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react'
import { useNavigate } from 'react-router-dom'
import { MdEmail } from "react-icons/md"
import { FaPhoneAlt } from "react-icons/fa"
import PopupDropdown from '../../PopupDropdown'
import httpClient from '../../helpers/httpClient'
import { toast } from "react-toastify"

const PurchaseSection = forwardRef(({ unit }, ref) => {
  const [pocs, setPocs] = useState([])
  const [contacts, setContacts] = useState([])
  const [purchaseOrderLines, setPurchaseOrderLines] = useState([])
  const [productCategoryList, setProductCategoryList] = useState([])
  const [products, setProducts] = useState([])
  const [productGroups, setProductGroups] = useState([])
  const navigate = useNavigate()

  useEffect(() => {
    Promise.all([
      fetchPocs(),
      fetchContacts(),
      fetchProductCategories(),
      fetchProducts(),
      fetchProductGroups()
    ])
  }, [])

  useImperativeHandle(ref, () => ({
    handleSavePurchaseOrderLines: async () => {
      console.log(purchaseOrderLines)
      // try {
      //   const response = await httpClient.post(
      //     process.env.REACT_APP_API_URL + `/api/units/purchase-orders/create`, 
      //     {
      //       unitID: unit.id,
      //       purchaseOrderLines
      //     }
      //   );
      //   return response.status === 200;
      // } catch (error) {
      //   console.error('Error saving purchase order lines:', error)
      //   return false;
      // }
    }
  }))

  const fetchPocs = async () => {
    const response = await httpClient.get(process.env.REACT_APP_API_URL + `/api/units/pocs/get?unitID=${unit.id}`)
    if (response.status == 200) {
      setPocs(response.data)
    }
  }

  const fetchContacts = async () => {
    const response = await httpClient.get(process.env.REACT_APP_API_URL + `/api/contacts/get`)
    if (response.status == 200) {
      setContacts(response.data)
    }
  }

  const fetchProductCategories = async () => {
    const response = await httpClient.get(process.env.REACT_APP_API_URL + `/api/product-categories/get`)
    if (response.status == 200) {
      setProductCategoryList(response.data)
    }
  }

  const fetchProducts = async () => {
    const response = await httpClient.get(process.env.REACT_APP_API_URL + `/api/products/get`)
    if (response.status == 200) {
      setProducts(response.data.map(product => ({
        ...product,
        name: `ID: ${product.id} | ${product.brand} - ${product.product}`
      })))
    }
  }

  const fetchProductGroups = async () => {
    const response = await httpClient.get(process.env.REACT_APP_API_URL + `/api/product/groups/get`)
    if (response.status == 200) {
      setProductGroups(response.data)
    }
  }

  const handleAddPoc = async (poc) => {
    const data = {
      unitID: unit.id,
      contactID: poc.id
    }
    const response = await toast.promise(
      httpClient.post(process.env.REACT_APP_API_URL + `/api/units/pocs/create`, data),
      {
        success: `Person of contact has been added!`,
        error: "Something went wrong",
        pending: `Adding person of contact...`
      }
    )

    fetchPocs()
  }

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text)
    toast.success("Copied to clipboard!")
  }

  const handleAddProductCategory = (category) => {
    setPurchaseOrderLines([...purchaseOrderLines, {
      category_name: category.name,
      category_id: category.id,
      order_lines: []
    }])
  }

  const handleAddProduct = (type, categoryIndex, valueObj) => {
    let orderLineCategory = purchaseOrderLines[categoryIndex]
    if (type == "product") {
      let newOrderLine = {
        product_id: valueObj.id,
        product_name: valueObj.product,
        product_brand: valueObj.brand,
        quantity: 1
      }

      orderLineCategory.order_lines.push(newOrderLine)
    } else if (type == "group") {
      console.log(valueObj)
    }
    setPurchaseOrderLines([...purchaseOrderLines])
  }

  console.log(purchaseOrderLines)

  return (
    <div className='popout_body__content_container' id="unit-purchase-section">
      <div className='top_container'>
        <div className='pocs-section'>
          <h2>
            Person of Contact
            <div className='add-poc-button'>
              <PopupDropdown
                title="+"
                options={contacts}
                onChange={handleAddPoc}
                searchBar={true}
              />
            </div>
          </h2>
          <div className='poc-list'>
            {pocs.map((poc, index) => (
              <div className='poc-item' key={index}>
                <div onClick={() => navigate(`/contacts/${poc.id}`)}>
                  {poc.first_name} {poc.last_name}
                </div>
                <div
                  onClick={() => handleCopy(poc.email)}
                  style={{ cursor: 'copy', display: 'flex', alignItems: 'center', gap: '8px' }}
                >
                  <MdEmail /> {poc.email}
                </div>
                <div
                  onClick={() => handleCopy(poc.mobile_phone)}
                  style={{ cursor: 'copy', display: 'flex', alignItems: 'center', gap: '8px' }}
                >
                  <FaPhoneAlt /> {poc.mobile_phone}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className='purchase-order-buttons'>
          <button className='action-button-header red'>
            Close template mode
          </button>
        </div>
      </div>
      <div className='bottom_container'>
        <h2>Purchase details</h2>
        <div className='purchase_details--container'>
          <div className='product_categories'>
            <div className='product_categories--list'>
              {purchaseOrderLines.map((purchaseOrderLine, categoryIndex) => (
                <div className='product_category--item' key={categoryIndex}>
                  <div className='product_category--title'>{categoryIndex + 1}. {purchaseOrderLine.category_name}</div>
                  <div className='product_categories--order_line__header'>
                    <div className='product_categories--order_line__header_name'>Product</div>
                    <div className='product_categories--order_line__header_quantity'>Quantity</div>
                  </div>
                  <div className='product_category--order_lines'>
                    {purchaseOrderLine.order_lines.map((orderLine, orderLineIndex) => (
                      <div className='product_category--order_line' key={orderLineIndex}>
                        <div className='product_category--order_line_name'>{categoryIndex + 1}.{orderLineIndex + 1} {orderLine.product_brand} - {orderLine.product_name}</div>
                        <div className='product_category--order_line_quantity'>{orderLine.quantity}</div>
                      </div>
                    ))}
                  </div>
                  <div className='product_category--buttons'>
                    <div className='po_add_button'>
                      <PopupDropdown
                        title="Add product"
                        options={products}
                        onChange={(product) => handleAddProduct("product", categoryIndex, product)}
                        searchBar={true}
                      />
                    </div>
                    <div className='po_add_button'>
                      <PopupDropdown
                        title="Add group"
                        options={productGroups}
                        onChange={(productGroup) => handleAddProduct("group", categoryIndex, productGroup)}
                        searchBar={true}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className='po_add_button'>
              <PopupDropdown
                title="Add product category"
                options={productCategoryList}
                onChange={handleAddProductCategory}
                searchBar={true}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
})

export default PurchaseSection 