import React, { useState } from 'react'
import httpClient from '../../helpers/httpClient'

function PriceSection({ unit, loading }) {
  const [commercialPrice, setCommercialPrice] = useState({})
  const [transactionPrice, setTransactionPrice] = useState({})

  // const fetchPriceDetails = async () => {
  //   const response = await httpClient.get(process.env.REACT_APP_API_URL + `/api/unit-price-details/${unit.unit_id}`)
  //   if (response.status == 200) {
  //     setCommercialPrice(response.data.commercial_price)
  //     setTransactionPrice(response.data.transactional_price)
  //   }
  // }

  // console.log(unit)

  return (
    <div className="section-container" id='unit-price-section'>
      <h2>Price Details</h2>

    </div>
  )
}

export default PriceSection 