import { createSlice } from "@reduxjs/toolkit";

export const transactionsPageSlice = createSlice({
  name: "transactionsPageState",
  initialState: {
    filtersTransactions: {
      page: 1,
      sortFilter: null,
      sortAscending: true,
      searchWord: "",
      typeFilter: null,
    },
    transactionsList: [],
  },
  reducers: {
    updateFilters: (state, action) => {
      state.filtersTransactions = action.payload;
    },
    updateTransactionsList: (state, action) => {
      const transactionsList = action.payload;
    
      // Check if transactionsList is an array
      if (Array.isArray(transactionsList)) {
        state.transactionsList = transactionsList;
      } else {
        state.transactionsList = []; // Set to an empty array if not an array
      }
    },
    resetStateTransactionsPage: (state) => {
      state.filtersTransactions = {
        page: 1,
        sortFilter: null,
        sortAscending: true,
        searchWord: "",
        typeFilter: null,
      };
      state.transactionsList = [];
    },
  },
})

export const { updateFilters, updateTransactionsList, resetStateTransactionsPage } = transactionsPageSlice.actions
export default transactionsPageSlice.reducer