import React, { useState, useEffect, useRef } from 'react'
import './ProductDetailPage.css'
import httpClient from '../components/helpers/httpClient'
import { useNavigate, useParams } from "react-router-dom"
import MenuBar from "../components/MenuBar"
import Header from "../components/Header"
import LoadingSpinner from "../components/placeholders/LoadingSpinner"
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import Dropdown from '../components/Dropdown'
import { toast } from "react-toastify"
import FileDropper from '../components/FileDropper'
import CloseIcon from '@mui/icons-material/Close'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import PopupDropdown from '../components/PopupDropdown'
import DropdownMultiselect from '../components/DropdownMultiselect'

const ImageViewer = ({ imageUrl, onClose }) => {
  return (
    <div className="image-viewer-overlay" onClick={onClose}>
      <div className="image-viewer-content">
        <img src={imageUrl} alt="Full size" />
        <button className="close-button" onClick={onClose}>×</button>
      </div>
    </div>
  )
}

function ProductDetailPage() {
  const [product, setProduct] = useState({})
  const [edit, setEdit] = useState(false)
  const [fileURL, setFileURL] = useState("")
  const [images, setImages] = useState([])
  const [files, setFiles] = useState([])
  const [prices, setPrices] = useState([])
  const [companies, setCompanies] = useState([])
  const [selectedImage, setSelectedImage] = useState(null)
  const [newPrice, setNewPrice] = useState({
    supplier_id: null,
    product_id_supplier: '',
    unit: '',
    price_net: '',
    price_gross: '',
    margin: ''
  })
  const [groups, setGroups] = useState([])
  const [selectedGroups, setSelectedGroups] = useState([])

  const navigate = useNavigate()
  const { productID } = useParams()

  const fetchProduct = async () => {
    const response = await httpClient.get(process.env.REACT_APP_API_URL + `/api/products/get?productID=${productID}`)

    const data = response.data

    setImages(data.product_images || [])
    setFiles(data.product_files || [])
    setFileURL(data.technical_fiche_link)
    setPrices(data.product_prices || [])
    setSelectedGroups(data.product_groups || [])
    setProduct(response.data)
  }

  const fetchCompanies = async () => {
    const response = await httpClient.get(process.env.REACT_APP_API_URL + `/api/companies/get`)
    // Map companies to only include id and name
    const mappedCompanies = response.data.map(company => ({
      id: company.id,
      name: company.company_name
    }))
    setCompanies(mappedCompanies)
  }

  const fetchGroups = async () => {
    const response = await httpClient.get(process.env.REACT_APP_API_URL + '/api/product/groups/get')
    setGroups(response.data)
  }

  useEffect(() => {
    fetchProduct()
    fetchCompanies()
    fetchGroups()
  }, [])

  const handleGoBack = () => {
    navigate(-1)
  }

  const handleEditProduct = async () => {
    if (edit) {
      const editButton = document.querySelector("#product__btn-edit")
      editButton.disabled = true

      // Add price
      await AddPrice()

      // Update product

      const formData = {}
      const inputElements = document.querySelectorAll('.input_field__input_box--text')

      inputElements.forEach(element => {
        const fieldName = element.name
        formData[fieldName] = element.value
      })

      formData["technical_fiche_link"] = fileURL

      const response = await toast.promise(
        httpClient.put(process.env.REACT_APP_API_URL + `/api/product/update?productID=${productID}`, formData),
        {
          pending: "Update product...",
          success: "Product has been updated 👍",
          error: "Something went wrong 😢"
        }
      )
      console.log(response.data)

      fetchProduct()

      editButton.disabled = false
      setEdit(!edit)
    } else {
      setEdit(true)
    }
  }

  const handleChangeInput = (e, type) => {
    const newProductObj = { ...product }
    newProductObj[type] = e.currentTarget.value

    setProduct(newProductObj)
  }

  const handleUploadFiles = async (e, type) => {
    if (e.target.files && e.target.files.length > 0) {
      const formData = new FormData()

      // Append all files
      Array.from(e.target.files).forEach(file => {
        formData.append('files[]', file)
      })
      formData.append('product_id', productID)

      try {
        const response = await toast.promise(
          httpClient.post(
            process.env.REACT_APP_API_URL + '/api/products/upload-files?type=' + type,
            formData
          ),
          {
            pending: 'Uploading images...',
            success: 'Images uploaded successfully',
            error: 'Failed to upload images'
          }
        )

        // Add all new image URLs to the existing images
        const newUrls = response.data.urls.map(item => item.url)
        if (type == "image") {
          setImages([...images, ...newUrls])
        } else {
          setFiles([...files, ...newUrls])
        }
      } catch (error) {
        console.error('Upload error:', error)
      }
    }

    fetchProduct()
  }

  const handleDeleteFile = async (fileId) => {
    try {
      await toast.promise(
        httpClient.delete(
          `${process.env.REACT_APP_API_URL}/api/products/delete-file?fileID=${fileId}`
        ),
        {
          pending: 'Deleting file...',
          success: 'File deleted successfully',
          error: 'Failed to delete file'
        }
      )

      // Refresh product data after deletion
      fetchProduct()
    } catch (error) {
      console.error('Delete error:', error)
    }
  }

  const handlePriceChange = (field, value) => {
    const updates = { ...newPrice, [field]: value }

    if (field === 'price_net' && updates.margin) {
      // Calculate gross price based on net price and margin
      updates.price_gross = (parseFloat(updates.price_net) * (1 + parseFloat(updates.margin) / 100)).toFixed(2)
    } else if (field === 'price_gross' && updates.price_net) {
      // Calculate margin based on net and gross prices
      updates.margin = (((parseFloat(updates.price_gross) / parseFloat(updates.price_net)) - 1) * 100).toFixed(2)
    } else if (field === 'margin' && updates.price_net) {
      // Calculate gross price based on net price and new margin
      updates.price_gross = (parseFloat(updates.price_net) * (1 + parseFloat(updates.margin) / 100)).toFixed(2)
    }

    setNewPrice(updates)
  }

  const AddPrice = async () => {
    try {
      await httpClient.post(process.env.REACT_APP_API_URL + '/api/products/add-price', {
        ...newPrice,
        product_id: productID,
        supplier_id: newPrice.supplier_id.id
      })

      // Reset form and refresh prices
      setNewPrice({
        supplier_id: null,
        product_id_supplier: '',
        unit: '',
        price_net: '',
        price_gross: '',
        margin: ''
      })
    } catch (error) {
      console.error('Add price error:', error)
    }
  }

  const handleGroupsChange = async (group, action) => {
    try {
      if (action === "add") {
        await httpClient.post(process.env.REACT_APP_API_URL + '/api/product/groups/add-to-group', {
          product_id: productID,
          group_id: group.id
        })
        setSelectedGroups(prev => [...prev, group])
      } else {
        await httpClient.post(process.env.REACT_APP_API_URL + `/api/product/groups/remove-from-group`, {
          product_id: productID,
          group_id: group.id
        })
        setSelectedGroups(prev => prev.filter(g => g.id !== group.id))
      }
    } catch (error) {
      console.error('Error updating groups:', error)
      // revert the local state change on error
      fetchProduct()
    }
  }

  return (
    <div className='template'>

      <Header>
        <div className="back_button" onClick={handleGoBack}><KeyboardBackspaceIcon /> Go Back</div>

        <div className='header__options--right'>
          <button className='primary-button-header' id="product__btn-edit" onClick={handleEditProduct}>{edit ? "SAVE" : "EDIT DETAILS"}</button>
          {edit && <button className='primary-button-header red' id="product__btn-edit" onClick={() => setEdit(false)}>CANCEL</button>}
        </div>
      </Header>

      <MenuBar />

      <div className="template__body" id='body-product-detail'>
        <div className="body__section" id="product-detail">
          {/* Top section with 3 columns */}
          <div className="form__section" id='product-info-section'>
            <h3>Basic Info</h3>
            <div className="input_field">
              <label className="input_field__label" htmlFor="email">Brand</label>
              <input className="input_field__input_box--text" id="products__brand" type="text" name="brand" value={product.brand} onChange={(e) => handleChangeInput(e, "brand")} disabled={!edit} />
            </div>

            <div className="input_field">
              <label className="input_field__label" htmlFor="tel">Product</label>
              <input className="input_field__input_box--text" id="products__product" type="text" name="product" value={product.product} onChange={(e) => handleChangeInput(e, "product")} disabled={!edit} />
            </div>

            <div className="input_field">
              <label className="input_field__label">Groups</label>
              <DropdownMultiselect
                listArray={groups}
                state={selectedGroups}
                setState={handleGroupsChange}
                width="100%"
                idList="groups-dropdown"
                displayValueKey="group_name"
                allowCreate={true}
                createEndpoint="/api/product/groups/create"
                createValueKey="group_name"
                disabled={!edit}
              />
            </div>

            <div className="input_field">
              <label className="input_field__label" htmlFor="tel">Description</label>
              <textarea className="input_field__input_box--text" id="product__product_description" type="text" name="product_description" value={product.product_description} onChange={(e) => handleChangeInput(e, "product_description")} disabled={!edit} />
            </div>

          </div>

          <div className="form__section" id="prices-section">
            <h3>Prices</h3>

            {/* Add new price form */}
            {edit && (
              <div className="add-price-form">
                <div className="input-row">
                  <div className="input-row__item">
                    <PopupDropdown
                      options={companies}
                      value={newPrice.supplier_id}
                      onChange={(supplier) => handlePriceChange('supplier_id', supplier)}
                      title="Select Supplier"
                      searchBar={true}
                    />
                  </div>
                  <input
                    type="text"
                    placeholder="Supplier SKU"
                    value={newPrice.product_id_supplier}
                    onChange={(e) => handlePriceChange('product_id_supplier', e.target.value)}
                    className="input_field__input_box--text"
                  />
                  <input
                    type="text"
                    placeholder="Unit"
                    value={newPrice.unit}
                    onChange={(e) => handlePriceChange('unit', e.target.value)}
                    className="input_field__input_box--text"
                  />
                </div>
                <div className="input-row">
                  <input
                    type="number"
                    placeholder="Net Price"
                    value={newPrice.price_net}
                    onChange={(e) => handlePriceChange('price_net', e.target.value)}
                    className="input_field__input_box--text"
                  />
                  <input
                    type="number"
                    placeholder="Margin %"
                    value={newPrice.margin}
                    onChange={(e) => handlePriceChange('margin', e.target.value)}
                    className="input_field__input_box--text"
                  />
                  <input
                    type="number"
                    placeholder="Gross Price"
                    value={newPrice.price_gross}
                    onChange={(e) => handlePriceChange('price_gross', e.target.value)}
                    className="input_field__input_box--text"
                  />
                </div>
              </div>
            )}

            {/* Display existing prices */}
            <div className="prices-list">
              <table className="prices-table">
                <thead>
                  <tr>
                    <th>Supplier</th>
                    <th>SKU</th>
                    <th>Unit</th>
                    <th>Net Price</th>
                    <th>Margin</th>
                    <th>Gross Price</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>
                  {prices.map((price, index) => (
                    <tr key={index}>
                      <td>{price.supplier_name}</td>
                      <td>{price.product_id_supplier || '-'}</td>
                      <td>{price.unit}</td>
                      <td>€{price.price_net}</td>
                      <td>{price.margin}%</td>
                      <td>€{price.price_gross}</td>
                      <td>{new Date(price.record_date).toLocaleDateString()}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <div className="form__section" id="image_upload">
            <h3>Images</h3>
            <div className='file__uploader_section'>
              <div className="photo-grid">
                {images.map((image, index) => (
                  <div
                    key={index}
                    className="photo-grid-item"
                    onClick={() => setSelectedImage(image.file_url)}
                  >
                    {edit && (
                      <button
                        className="delete-image-button"
                        onClick={(e) => {
                          e.stopPropagation()
                          handleDeleteFile(image.id)
                        }}
                      >
                        <CloseIcon />
                      </button>
                    )}
                    <img src={image.thumb_url} alt={`Product ${index + 1}`} />
                  </div>
                ))}
                {edit && (
                  <label className="photo-grid-item add-photo-button">
                    <input
                      type="file"
                      accept="image/*"
                      multiple={true}
                      onChange={(e) => handleUploadFiles(e, "image")}
                    />
                    +
                  </label>
                )}
              </div>
              {selectedImage && (
                <ImageViewer
                  imageUrl={selectedImage}
                  onClose={() => setSelectedImage(null)}
                />
              )}
            </div>
          </div>

          <div className="form__section" id="file_upload">
            <h3>Technical files</h3>
            <div className='file__uploader_section'>
              <div className="file-list">
                {files.map((file, index) => (
                  <div key={index} className="file-list-item">
                    <a href={file.file_url} download className="file-download-link">
                      <FileDownloadIcon />
                      <span className="file-name">{file.file_name || 'Document'}</span>
                    </a>
                    {edit && (
                      <button
                        className="delete-file-button"
                        onClick={() => handleDeleteFile(file.id)}
                      >
                        <CloseIcon />
                      </button>
                    )}
                  </div>
                ))}
                {edit && (
                  <label className="file-list-item add-file-button">
                    <input
                      type="file"
                      multiple={true}
                      onChange={(e) => handleUploadFiles(e, "file")}
                    />
                    +
                  </label>
                )}
              </div>
            </div>
          </div>
        </div>

      </div>

    </div>
  )
}

export default ProductDetailPage