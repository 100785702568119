import React, { useEffect, useRef, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import "./DropDownFilter.css"
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import ClearIcon from '@mui/icons-material/Clear'

let key = 0

function DropDownFilter(props) {
  const [searchValue, setSearchValue] = useState("")

  const dispatch = useDispatch()

  const ref = useRef(null)
  const idList = props.idList

  const onClickOutside = () => {
    const dropdownList = document.querySelectorAll(`#${idList}`)

    Array.from(dropdownList).forEach(dropdown => {
      dropdown.classList.remove("active")
    })
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside && onClickOutside()
      }
    }
    document.addEventListener("click", handleClickOutside, true)

    return () => {
      document.removeEventListener("click", handleClickOutside, true)
    }
  }, [onClickOutside])

  const handleClickDropdown = (event) => {
    if (props.value == "") {

      const dropdownList = event.currentTarget.nextSibling
      dropdownList.classList.toggle("active")

      const searchBar = document.getElementById(`search-bar--${idList}`)
      searchBar.focus()
    } else {

      const updatedFilters = { ...props.filters }

      if (typeof props.listArray[0] === 'object') {
        updatedFilters[props.filter] = {"id": null, "name": ""}
      } else {
        updatedFilters[props.filter] = ""
      }

      dispatch(props.setValue(updatedFilters))
      document.getElementById(idList).previousSibling.firstChild.innerHTML = props.placeholderText
    }
  }

  const handleOnClickItem = (event, value) => {
    event.target.parentElement.classList.toggle("active")
    props.onClickItem(event, value, props.idList)
  }

  return (
    <div className="dropdown__container filter flex--vert" ref={ref}>
      <span className="input_field__input_box--text--filter" onClick={handleClickDropdown}>
        <p className="dropdown-input--text_field">{props.value == "" ? props.placeholderText : props.value}</p>
        {props.value == "" ? <KeyboardArrowDownIcon /> : <ClearIcon />}
      </span>
      <ul className="input_field__dropdown filter" id={idList}>
        <input className="search-bar" id={`search-bar--${idList}`} placeholder="Search..." value={searchValue} onChange={event => setSearchValue(event.currentTarget.value)} autoComplete="off" />
        {
          props.listArray
            .filter(item => {
              if (typeof item === "object") {
                if (props.searchType == "startsWith") {
                  return item.name.toLocaleLowerCase().startsWith(searchValue)
                }

                return item.name.toLocaleLowerCase().includes(searchValue)
              } else {
                if (props.searchType == "startsWith") {
                  return item.toLocaleLowerCase().startsWith(searchValue)
                }

                return item.toLocaleLowerCase().includes(searchValue)
              }
            })
            .map(item => {
              if (typeof item === "object") {
                return <li key={key++} onClick={(event) => handleOnClickItem(event, item.id)} data-id={item.id}>{item.name}</li>
              } else {
                return <li key={key++} onClick={(event) => handleOnClickItem(event, item)}>{item}</li>
              }
            })
        }
      </ul>
    </div>
  )
}

export default DropDownFilter
