import React, {useState, useEffect} from "react"
import httpClient from "./helpers/httpClient"
import "./Header.css"
import { useSelector, useDispatch } from "react-redux"

function Header(props) {
  const quotes = [
    { text: "Ninety percent of all millionaires become so through owning real estate.", author: "Andrew Carnegie" },
    { text: "The best time to buy a home is always five years ago.", author: "Ray Brown" },
    { text: "Real estate is an impermanent and imperfectly competitive industry.", author: "Sam Zell" },
    { text: "The most important thing in communication is hearing what isn't said.", author: "Peter Drucker" },
    { text: "The best investment on earth is earth.", author: "Louis Glickman" },
    { text: "The future belongs to those who believe in the beauty of their dreams.", author: "Eleanor Roosevelt" },
    { text: "Success is not final, failure is not fatal: It is the courage to continue that counts.", author: "Winston Churchill" },
    { text: "The biggest risk is not taking any risk...", author: "Mark Zuckerberg" },
    { text: "The key is not to prioritize what's on your schedule, but to schedule your priorities.", author: "Stephen Covey" },
    { text: "Vision is the art of seeing what is invisible to others.", author: "Jonathan Swift" },
    { text: "The best way to predict the future is to create it.", author: "Abraham Lincoln" },
    { text: "Do not watch the clock; do what it does. Keep going.", author: "Sam Levenson" },
    { text: "Buy land, they're not making it anymore.", author: "Mark Twain" },
    { text: "Owning a home is a keystone of wealth... both financial affluence and emotional security.", author: "Suze Orman" },
    { text: "Real estate cannot be lost or stolen, nor can it be carried away. Purchased with common sense, paid for in full, and managed with reasonable care, it is about the safest investment in the world.", author: "Franklin D. Roosevelt" },
    { text: "Landlords grow rich in their sleep without working, risking or economizing.", author: "John Stuart Mill" }
  ];

  const getWeekNumber = () => {
    const now = new Date();
    const onejan = new Date(now.getFullYear(), 0, 1);
    return Math.ceil((((now.getTime() - onejan.getTime()) / 86400000) + onejan.getDay() + 1) / 7);
  };

  const userInfo = useSelector(state => state.user);
  let message;

  switch (userInfo.type) {
    case "admin":
      message = "I hope you are having an awesome day";
      break;
    default:
      const weekNumber = getWeekNumber();
      const quoteIndex = weekNumber % quotes.length;
      const quote = quotes[quoteIndex];
      message = `"${quote.text}" - ${quote.author}`;
  }

  return (
    <div className="template__header" id="header">
      <div id="header-title" className="hide">
        <h1>Hi {userInfo.first_name}! 🥷</h1>
        <p className="header--p">{message}</p>
      </div>
      {props.children}
    </div>
)}

export default Header
