export const formatDate = (dateStr) => {
  const d = new Date(dateStr);
  let day = d.getUTCDate().toString();
  let month = (d.getUTCMonth() + 1).toString();
  const year = d.getUTCFullYear().toString();
  let hours = d.getUTCHours().toString();
  let minutes = d.getUTCMinutes().toString();

  // Adding leading zeros if needed
  day = day.length === 1 ? '0' + day : day;
  month = month.length === 1 ? '0' + month : month;
  hours = hours.length === 1 ? '0' + hours : hours;
  minutes = minutes.length === 1 ? '0' + minutes : minutes;

  return `${day}/${month}/${year}`;
}

export const formatDateWithTime = (dateStr) => {
  const d = new Date(dateStr);
  let day = d.getUTCDate().toString();
  let month = (d.getUTCMonth() + 1).toString();
  const year = d.getUTCFullYear().toString();
  let hours = d.getUTCHours().toString();
  let minutes = d.getUTCMinutes().toString();

  // Adding leading zeros if needed
  day = day.length === 1 ? '0' + day : day;
  month = month.length === 1 ? '0' + month : month;
  hours = hours.length === 1 ? '0' + hours : hours;
  minutes = minutes.length === 1 ? '0' + minutes : minutes;

  return `${day}/${month}/${year} ${hours}:${minutes}`;
}