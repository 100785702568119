import React, { useState, useEffect } from 'react'
import httpClient from './helpers/httpClient'
import './PopupWindowTransaction.css'
import PopupDropdown from './PopupDropdown'
import { DateField } from '@mui/x-date-pickers'
import CircularProgress from '@mui/material/CircularProgress'
import { toast } from "react-toastify"
import dayjs from 'dayjs'
import { showNotificationPopup } from './NotificationPopup'
import { formatThousands } from './helpers/formatNumbers'
import moment from 'moment';

export function PopupWindowTransaction({ isActive, setIsActive, transactionActionType, selectedTransactionID, fetchTransactions }) {

  const [loadingProperties, setLoadingProperties] = useState(false)
  const [propertySearchValue, setPropertySearchValue] = useState("")
  const [properties, setProperties] = useState([])
  const [newTransactionObj, setNewTransactionObj] = useState({
    type: "",
    reservationDate: "",
    contractDate: "",
    deedDate: "",
    landPrice: "",
    constructionPrice: "",
    transactionPrice: "0",
    notaryBuyer: "",
    notarySeller: ""
  })
  const [selectedProperties, setSelectedProperties] = useState([])
  const [contacts, setContacts] = useState([])
  const [companies, setCompanies] = useState([])
  const [sellerSearchValue, setSellerSearchValue] = useState("")
  const [buyerSearchValue, setBuyerSearchValue] = useState("")
  const [selectedSellers, setSelectedSellers] = useState([])
  const [selectedBuyers, setSelectedBuyers] = useState([])
  const OWNERSHIP_TYPES = ["volle eigendom", "vrucht gebruik", "naakte eigendom"]
  const OWNERSHIP_TYPE_OPTIONS = [
    "volle eigendom",
    "vrucht gebruik",
    "naakte eigendom",
    "erfpachtgever",
    "erfpachthouder",
    "opstalgever",
    "opstalhouder"
  ]
  const [menuItem, setMenuItem] = useState("properties")
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)

  const calculatePercentage = (numerator, denominator) => {
    if (!numerator || !denominator) return 0;
    return (Number(numerator) / Number(denominator)) * 100;
  };

  const fetchTransaction = async () => {
    const response = await httpClient.get(process.env.REACT_APP_API_URL + `/api/transactions/get?transactionID=${selectedTransactionID}`)

    if (response.status == 200) {
      // Transform notary data into simplified format
      const notaryBuyer = response.data.notary_buyer ? {
        id: response.data.notary_buyer.id,
        name: response.data.notary_buyer.company_name
      } : ""

      const notarySeller = response.data.notary_seller ? {
        id: response.data.notary_seller.id,
        name: response.data.notary_seller.company_name
      } : ""

      setNewTransactionObj({
        ...newTransactionObj,
        transactionID: response.data.id,
        type: response.data.type,
        reservationDate: response.data.reservation_date ? dayjs(response.data.reservation_date) : null,
        contractDate: response.data.contract_date ? dayjs(response.data.contract_date) : null,
        deedDate: response.data.deed_date ? dayjs(response.data.deed_date) : null,
        landPrice: response.data.land_price,
        constructionPrice: response.data.construction_price,
        transactionPrice: response.data.transaction_price,
        notaryBuyer,
        notarySeller
      })

      // Transform seller and buyer data to match the UI format
      const sellers = response.data.sellers.map(seller => ({
        ...seller,
        id: seller.contact?.id || seller.company?.id,
        first_name: seller.contact?.first_name || seller.company?.company_name,
        last_name: seller.contact?.last_name || '',
        type: seller.contact ? 'contact' : 'company',
        ownership_numerator: seller.ownership_numerator,
        ownership_denominator: seller.ownership_denominator,
        ownership_type: seller.ownership_type
      }))

      const buyers = response.data.buyers.map(buyer => ({
        ...buyer,
        id: buyer.contact?.id || buyer.company?.id,
        first_name: buyer.contact?.first_name || buyer.company?.company_name,
        last_name: buyer.contact?.last_name || '',
        type: buyer.contact ? 'contact' : 'company',
        ownership_numerator: buyer.ownership_numerator,
        ownership_denominator: buyer.ownership_denominator,
        ownership_type: buyer.ownership_type
      }))

      setSelectedBuyers(buyers)
      setSelectedSellers(sellers)
      setSelectedProperties(response.data.properties)
    }
    return response
  }

  const fetchProperties = async () => {
    setLoadingProperties(true)
    const response = await httpClient.get(process.env.REACT_APP_API_URL + `/api/properties/get?type=including-projects-units`)
    if (response.status == 200) {
      setProperties(response.data)
    }
    setLoadingProperties(false)
  }

  const fetchContacts = async () => {
    const response = await httpClient.get(process.env.REACT_APP_API_URL + `/api/contacts/get`)
    if (response.status == 200) {
      setContacts(response.data)
    }
  }

  const fetchCompanies = async () => {
    const response = await httpClient.get(process.env.REACT_APP_API_URL + `/api/companies/get?type=limited-info`)
    if (response.status == 200) {
      setCompanies(response.data)
    }
  }

  useEffect(() => {
    const popup = document.getElementById("popup-transaction")
    window.addEventListener('keydown', e => {
      if (e.key === 'Escape') {
        if (popup.classList.contains("active")) {
          closePopupWindow()
        }
      }
    })
  }, [])

  useEffect(() => {
    fetchProperties()
    fetchContacts()
    fetchCompanies()
  }, [])

  useEffect(() => {
    setNewTransactionObj({
      ...newTransactionObj,
      sellers: selectedSellers,
      buyers: selectedBuyers,
      properties: selectedProperties
    })
  }, [selectedBuyers, selectedProperties, selectedSellers])

  useEffect(() => {
    if (transactionActionType == "edit") {
      fetchTransaction().catch(error => {
        if (error.response?.status === 404) {
          console.log("Transaction not found")
        } else {
          console.log("Error fetching transaction:", error)
        }
      })
    } else {
      setNewTransactionObj({
        ...newTransactionObj,
        type: "",
        reservationDate: null,
        contractDate: null,
        deedDate: null,
        landPrice: "",
        constructionPrice: "",
        transactionPrice: "0",
        notaryBuyer: "",
        notarySeller: ""
      })
      setSelectedBuyers([])
      setSelectedSellers([])
      setSelectedProperties([])
    }
  }, [selectedTransactionID, transactionActionType])

  const handleSetStateNewTransactionType = (value) => {
    setNewTransactionObj({ ...newTransactionObj, type: value })
  }

  const handleSetStateNewTransactionObj = (field, value) => {
    setNewTransactionObj({ ...newTransactionObj, [field]: value })
  }

  const closePopupWindow = () => {
    setIsActive(false)
  }

  const handleSaveNewTransaction = async () => {
    // Validate required fields
    const requiredFields = {
      type: "Transaction type",
      properties: "Properties",
      sellers: "Sellers",
      buyers: "Buyers",
    }

    for (const [field, label] of Object.entries(requiredFields)) {
      if (!newTransactionObj[field] ||
        (Array.isArray(newTransactionObj[field]) && newTransactionObj[field].length === 0)) {
        toast.error(`${label} is required`)
        return
      }
    }

    // If all validations pass, proceed with creating the transaction

    if (transactionActionType == "edit") {
      const response = await httpClient.put(process.env.REACT_APP_API_URL + `/api/transactions/update`, newTransactionObj)
      if (response.status == 200) {
        fetchTransactions()
        closePopupWindow()
        toast.success("Transaction updated successfully")
      } else {
        toast.error("Error updating transaction")
      }
    } else {
      const response = await httpClient.post(process.env.REACT_APP_API_URL + `/api/transactions/create`, newTransactionObj)
      if (response.status == 200) {
        fetchTransactions()
        closePopupWindow()
        toast.success("Transaction created successfully")
      } else {
        toast.error("Error creating transaction")
      }
    }
  }

  const handleDeleteTransaction = () => {
    showNotificationPopup({
      message: "Are you sure you want to delete this transaction?",
      onAction: confirmDelete,
      actionButton: {
        text: "Delete",
        color: "var(--red)"
      }
    })
  }

  const confirmDelete = async () => {
    const response = await httpClient.delete(process.env.REACT_APP_API_URL + `/api/transactions/delete?transactionID=${selectedTransactionID}`)
    if (response.status === 200) {
      toast.success("Transaction deleted successfully")
      fetchTransactions()
      closePopupWindow()
    } else {
      toast.error("Error deleting transaction")
    }
    setShowDeleteConfirmation(false)
  }

  const handleSelectProperty = (property) => {
    // Check if property is already selected to avoid duplicates
    if (selectedProperties.some(p => p.id === property.id)) {
      return
    }
    setSelectedProperties([...selectedProperties, property])
  }

  const handleRemoveProperty = (property) => {
    setSelectedProperties(selectedProperties.filter(p => p.id !== property.id))
  }

  const handleSetStateNewTransactionNotaryBuyer = (value) => {
    setNewTransactionObj({ ...newTransactionObj, notaryBuyer: value })
  }

  const handleSetStateNewTransactionNotarySeller = (value) => {
    setNewTransactionObj({ ...newTransactionObj, notarySeller: value })
  }

  const handleSelectSeller = (contact) => {
    if (selectedSellers.some(s => s.id === contact.id)) {
      return;
    }

    setSelectedSellers([...selectedSellers, {
      ...contact,
      ownership_numerator: "1",
      ownership_denominator: "1",
      ownership_type: "volle eigendom"
    }]);
  }

  const handleRemoveSeller = (contact) => {
    setSelectedSellers(selectedSellers.filter(s => s.id !== contact.id))
  }

  const handleSelectBuyer = (contact) => {
    if (selectedBuyers.some(b => b.id === contact.id)) {
      return
    }

    // Calculate total existing percentage
    const totalExistingPercentage = selectedBuyers.reduce((sum, buyer) =>
      sum + (Number(buyer.ownership_numerator) / Number(buyer.ownership_denominator) * 100 || 0), 0
    )

    // Calculate remaining percentage (minimum 0)
    const remainingPercentage = Math.max(100 - totalExistingPercentage, 0)

    setSelectedBuyers([...selectedBuyers, {
      ...contact,
      ownership_numerator: "1",
      ownership_denominator: "1",
      ownership_type: "volle eigendom"
    }])
  }

  const handleRemoveBuyer = (contact) => {
    setSelectedBuyers(selectedBuyers.filter(b => b.id !== contact.id))
  }

  const updateSellerDetails = (contact, field, value) => {
    setSelectedSellers(selectedSellers.map(seller =>
      seller.id === contact.id
        ? { ...seller, [field]: value }
        : seller
    ))
  }

  const updateBuyerDetails = (contact, field, value) => {
    setSelectedBuyers(selectedBuyers.map(buyer =>
      buyer.id === contact.id
        ? { ...buyer, [field]: value }
        : buyer
    ))
  }

  return (
    <>
      <div
        className={`popup__container overlay no-scrollbar ${isActive ? "active" : ""}`}
        id="popup-transaction"
        onKeyDown={e => e.key === "Escape" && closePopupWindow()}
      >
        <div
          className='popup__box no-scrollbar'
          onClick={e => e.stopPropagation()}
        >
          <div className='close_button' onClick={closePopupWindow}>x</div>
          <div className='popout_body' id='popout-body-project'>
            <div className='popout_window__header'>
              <div className='header_buttons'>
                {transactionActionType == "edit" && (
                  <button className='action-button-header red ' onClick={handleDeleteTransaction}>DELETE</button>
                )}
              </div>
              <div className='popout_window__header--title'>{transactionActionType == "create" ? "Create Transaction" : "Transaction Details"}</div>
              <button className='primary-button-header' onClick={handleSaveNewTransaction}>SAVE</button>
            </div>

            <div className='menu__titles'>
              <h3 className={`${menuItem == "properties" ? "selected" : ""}`} onClick={() => setMenuItem("properties")}>properties</h3>
              <h3 className={`${menuItem == "details" ? "selected" : ""}`} onClick={() => setMenuItem("details")}>details</h3>
            </div>

            <div className={`popout_body__content_container ${menuItem}`}>
              {
                menuItem === "properties" && (
                  <div className='panel'>
                    <div className='selected_properties_list'>
                      <h3>Selected properties</h3>
                      <input
                        className='search-bar--list'
                        type="text"
                        name="property_name"
                        value={propertySearchValue}
                        onChange={(e) => setPropertySearchValue(e.currentTarget.value)}
                        placeholder="Search property"
                      />
                      <div className='properties_list'>
                        {
                          selectedProperties.map((property, index) => {
                            // Get unit names from property_x_unit_set
                            const unitNames = property.property_x_unit_set.map(pxu => pxu.unit_id.unit_name).join(', ')
                            // Get project names from property_x_unit_set
                            const projectNames = property.property_x_unit_set
                              .map(pxu => pxu.unit_id.building_id.project_id.project_placeholder_name)
                              .filter((value, index, self) => self.indexOf(value) === index) // Remove duplicates
                              .join(', ')

                            return (
                              <div className='selected_property_item' key={index} onClick={() => handleRemoveProperty(property)}>
                                <div className='property_item_value'>{property.cadastral_nr}</div>
                                <div className='property_item_value'>{property.location}</div>
                                <div className='property_item_value'>{property.function}</div>
                                <div className='property_item_value'>{unitNames}</div>
                                <div className='property_item_value'>{projectNames}</div>
                                <div className='property_item__icon min'>
                                  -
                                </div>
                              </div>
                            )
                          })
                        }
                        {
                          loadingProperties ? (
                            <div className='loading__container'>
                              <CircularProgress size={30} color="inherit" />
                            </div>
                          ) : (
                            properties
                              .filter(property => (
                                !selectedProperties.some(p => p.id === property.id) && (
                                  property?.cadastral_nr?.toLowerCase().includes(propertySearchValue.toLowerCase()) ||
                                  property?.location?.toLowerCase().includes(propertySearchValue.toLowerCase()) ||
                                  property?.function?.toLowerCase().includes(propertySearchValue.toLowerCase()) ||
                                  // Search in unit names
                                  property?.property_x_unit_set?.some(pxu =>
                                    pxu.unit_id.unit_name.toLowerCase().includes(propertySearchValue.toLowerCase())
                                  ) ||
                                  // Search in project names
                                  property?.property_x_unit_set?.some(pxu =>
                                    pxu.unit_id.building_id.project_id.project_placeholder_name.toLowerCase().includes(propertySearchValue.toLowerCase())
                                  )
                                )
                              ))
                              .map((property, index) => {
                                // Get unit names from property_x_unit_set
                                const unitNames = property.property_x_unit_set.map(pxu => pxu.unit_id.unit_name).join(', ')
                                // Get project names from property_x_unit_set
                                const projectNames = property.property_x_unit_set
                                  .map(pxu => pxu.unit_id.building_id.project_id.project_placeholder_name)
                                  .filter((value, index, self) => self.indexOf(value) === index) // Remove duplicates
                                  .join(', ')

                                return (
                                  <div className='property-item' key={index} onClick={() => handleSelectProperty(property)}>
                                    <div className='property_item_value'>{property.cadastral_nr}</div>
                                    <div className='property_item_value'>{property.location}</div>
                                    <div className='property_item_value'>{property.function}</div>
                                    <div className='property_item_value'>{unitNames}</div>
                                    <div className='property_item_value'>{projectNames}</div>
                                    <div className='property_item__icon plus'>
                                      +
                                    </div>
                                  </div>
                                )
                              })
                          )
                        }
                      </div>
                    </div>
                  </div>
                )}

              {
                menuItem === "details" && (
                  <>
                    <div className='panel overflow-y-auto'>
                      <div className="input_field--combo-grid">
                        <label className="input_field__label" htmlFor="transaction_type">Type</label>
                        <PopupDropdown
                          options={["Oorsprong", "Aankoop", "Verkoop"]}
                          value={newTransactionObj.type}
                          onChange={handleSetStateNewTransactionType}
                          title={"Select type"}
                          searchBar={false}
                        />
                      </div>

                      <div className="input_field--combo-grid">
                        <label className="input_field__label" htmlFor="reservation_date">Reservation date</label>
                        <DateField
                          className="date-picker"
                          format="DD-MM-YYYY"
                          value={newTransactionObj.reservationDate ? moment(newTransactionObj.reservationDate) : null}
                          onChange={(value) => {
                            // Format as ISO string but only keep the date part
                            const formattedDate = value ? value.format('YYYY-MM-DD') : null;
                            handleSetStateNewTransactionObj("reservationDate", formattedDate);
                          }}
                        />
                      </div>
                      <div className="input_field--combo-grid">
                        <label className="input_field__label" htmlFor="contract_date">Contract date</label>
                        <DateField
                          className="date-picker"
                          format="DD-MM-YYYY"
                          value={newTransactionObj.contractDate ? moment(newTransactionObj.contractDate) : null}
                          onChange={(value) => {
                            const formattedDate = value ? value.format('YYYY-MM-DD') : null;
                            handleSetStateNewTransactionObj("contractDate", formattedDate);
                          }}
                        />
                      </div>
                      <div className="input_field--combo-grid">
                        <label className="input_field__label" htmlFor="deed_date">Deed date</label>
                        <DateField
                          className="date-picker"
                          format="DD-MM-YYYY"
                          value={newTransactionObj.deedDate ? moment(newTransactionObj.deedDate) : null}
                          onChange={(value) => {
                            const formattedDate = value ? value.format('YYYY-MM-DD') : null;
                            handleSetStateNewTransactionObj("deedDate", formattedDate);
                          }}
                        />
                      </div>

                      <div className="input_field--combo-grid">
                        <div className="price-inputs-container">
                          <div className="sub-price-input">
                            <label className="input_field__label" htmlFor="land_price">Land Price</label>
                            <div className="price-input-container">
                              <input
                                className="input_field__input_box--text price"
                                id="land_price"
                                type="text"
                                name="land_price"
                                value={newTransactionObj.landPrice}
                                onChange={(e) => {
                                  const landPrice = parseFloat(e.target.value.replace(/[.,\s]/g, '')) || 0
                                  const constructionPrice = parseFloat(newTransactionObj.constructionPrice.replace(/[.,\s]/g, '')) || 0
                                  setNewTransactionObj({
                                    ...newTransactionObj,
                                    landPrice: e.target.value,
                                    transactionPrice: (landPrice + constructionPrice).toString()
                                  })
                                }}
                                onBlur={(e) => {
                                  setNewTransactionObj({
                                    ...newTransactionObj,
                                    landPrice: formatThousands(e.target.value)
                                  })
                                }}
                                onFocus={(e) => {
                                  // Remove formatting when focusing to allow easy editing
                                  setNewTransactionObj({
                                    ...newTransactionObj,
                                    landPrice: e.target.value.replace(/[.,\s]/g, '')
                                  })
                                }}
                              />
                            </div>
                          </div>
                          <div className="sub-price-input">
                            <label className="input_field__label" htmlFor="construction_price">Construction Price</label>
                            <div className="price-input-container">
                              <input
                                className="input_field__input_box--text price"
                                id="construction_price"
                                type="text"
                                name="construction_price"
                                value={newTransactionObj.constructionPrice}
                                onChange={(e) => {
                                  const constructionPrice = parseFloat(e.target.value.replace(/[.,\s]/g, '')) || 0
                                  const landPrice = parseFloat(newTransactionObj.landPrice.replace(/[.,\s]/g, '')) || 0
                                  setNewTransactionObj({
                                    ...newTransactionObj,
                                    constructionPrice: e.target.value,
                                    transactionPrice: (landPrice + constructionPrice).toString()
                                  })
                                }}
                                onBlur={(e) => {
                                  setNewTransactionObj({
                                    ...newTransactionObj,
                                    constructionPrice: formatThousands(e.target.value)
                                  })
                                }}
                                onFocus={(e) => {
                                  // Remove formatting when focusing to allow easy editing
                                  setNewTransactionObj({
                                    ...newTransactionObj,
                                    constructionPrice: e.target.value.replace(/[.,\s]/g, '')
                                  })
                                }}
                              />
                            </div>
                          </div>
                          <div className="total-price">
                            <label className="input_field__label" htmlFor="transaction_price">Total Price</label>
                            <div className="price-input-container">
                              <input
                                className="input_field__input_box--text price"
                                id="transaction_price"
                                type="text"
                                name="transaction_price"
                                value={formatThousands(newTransactionObj.transactionPrice)}
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="input_field--combo-grid">
                        <label className="input_field__label" htmlFor="notary_buyer">Notary Buyer</label>
                        <PopupDropdown
                          searchBar={true}
                          options={companies}
                          value={newTransactionObj.notaryBuyer}
                          onChange={(value) => handleSetStateNewTransactionNotaryBuyer(value)}
                          title={"Select notary buyer"}
                        />
                      </div>

                      <div className="input_field--combo-grid">
                        <label className="input_field__label" htmlFor="notary_seller">Notary Seller</label>
                        <PopupDropdown
                          searchBar={true}
                          options={companies}
                          value={newTransactionObj.notarySeller}
                          onChange={(value) => handleSetStateNewTransactionNotarySeller(value)}
                          title={"Select notary seller"}
                        />
                      </div>

                    </div>

                    <div className='panel'>
                      <div className='selected_contacts_list'>
                        <div className='sellers_list'>
                          <h3>Sellers</h3>
                          <div className="search-bar--list-container">
                            <input
                              className='search-bar--list'
                              type="text"
                              name="seller_name"
                              value={sellerSearchValue}
                              onChange={(e) => setSellerSearchValue(e.currentTarget.value)}
                              placeholder="Search seller"
                            />
                            {sellerSearchValue && (
                              <div
                                className="search-bar--clear-icon"
                                onClick={() => setSellerSearchValue('')}
                              >
                                ×
                              </div>
                            )}
                          </div>
                          <div className='contacts_list'>
                            {selectedSellers.map((seller, index) => (
                              <div className='selected_contact_item' key={index}>
                                <div className='contact_item_value'>
                                  {seller.type === "contact" ? `${seller.first_name} ${seller.last_name}` : seller.company_name}
                                </div>
                                <div className='contact_item_inputs'>
                                  <div className="fraction-input-container">
                                    <input
                                      type="number"
                                      min="1"
                                      className="fraction-input numerator"
                                      value={seller.ownership_numerator || ''}
                                      onChange={(e) => updateSellerDetails(seller, 'ownership_numerator', e.target.value)}
                                      onClick={(e) => e.stopPropagation()}
                                    />
                                    <span className="fraction-divider">/</span>
                                    <input
                                      type="number"
                                      min="1"
                                      className="fraction-input denominator"
                                      value={seller.ownership_denominator || ''}
                                      onChange={(e) => updateSellerDetails(seller, 'ownership_denominator', e.target.value)}
                                      onClick={(e) => e.stopPropagation()}
                                    />
                                  </div>
                                  <PopupDropdown
                                    options={OWNERSHIP_TYPE_OPTIONS}
                                    value={seller.ownership_type || ''}
                                    onChange={(value) => updateSellerDetails(seller, 'ownership_type', value)}
                                    title="Select type"
                                    onClick={(e) => e.stopPropagation()}
                                    formatType="input-field"
                                  />
                                </div>
                                <div className='property_item__icon min' onClick={() => handleRemoveSeller(seller)}>-</div>
                              </div>
                            ))}
                            {[
                              ...contacts.map(item => ({ ...item, type: 'contact' })),
                              ...companies.map(item => ({ ...item, type: 'company' }))
                            ]
                              .filter(item => (
                                !selectedSellers.some(seller =>
                                  (seller.type === item.type && seller.id === item.id)
                                ) && (
                                  (`${item.first_name} ${item.last_name}`.toLowerCase().includes(sellerSearchValue.toLowerCase()) ||
                                    item.company_name?.toLowerCase().includes(sellerSearchValue.toLowerCase()))
                                )
                              ))
                              .map((item, index) => (
                                <div className='contact-item' key={index} onClick={() => handleSelectSeller(item)}>
                                  <div className='contact_item_value'>
                                    {item.type === 'company' ? item.company_name : `${item.first_name} ${item.last_name}`}
                                  </div>
                                  <div className='contact_item_value'>{item.email}</div>
                                  <div className='contact_item_value'>{item.type}</div>
                                  <div className='property_item__icon plus'>+</div>
                                </div>
                              ))}
                          </div>
                        </div>

                        <div className='buyers_list'>
                          <h3>Buyers</h3>
                          <div className="search-bar--list-container">
                            <input
                              className='search-bar--list'
                              type="text"
                              name="buyer_name"
                              value={buyerSearchValue}
                              onChange={(e) => setBuyerSearchValue(e.currentTarget.value)}
                              placeholder="Search buyer"
                            />
                            {buyerSearchValue && (
                              <div
                                className="search-bar--clear-icon"
                                onClick={() => setBuyerSearchValue('')}
                              >
                                ×
                              </div>
                            )}
                          </div>
                          <div className='contacts_list'>
                            {selectedBuyers.map((buyer, index) => (
                              <div className='selected_contact_item' key={index}>
                                <div className='contact_item_value'>{buyer.type === "contact" ? `${buyer.first_name} ${buyer.last_name}` : buyer.company_name}</div>
                                <div className='contact_item_inputs'>
                                  <div className="fraction-input-container">
                                    <input
                                      type="number"
                                      min="1"
                                      className="fraction-input numerator"
                                      value={buyer.ownership_numerator || ''}
                                      onChange={(e) => updateBuyerDetails(buyer, 'ownership_numerator', e.target.value)}
                                      onClick={(e) => e.stopPropagation()}
                                    />
                                    <span className="fraction-divider">/</span>
                                    <input
                                      type="number"
                                      min="1"
                                      className="fraction-input denominator"
                                      value={buyer.ownership_denominator || ''}
                                      onChange={(e) => updateBuyerDetails(buyer, 'ownership_denominator', e.target.value)}
                                      onClick={(e) => e.stopPropagation()}
                                    />
                                  </div>
                                  <PopupDropdown
                                    options={OWNERSHIP_TYPE_OPTIONS}
                                    value={buyer.ownership_type || ''}
                                    onChange={(value) => updateBuyerDetails(buyer, 'ownership_type', value)}
                                    title="Select type"
                                    onClick={(e) => e.stopPropagation()}
                                    formatType="input-field"
                                  />
                                </div>
                                <div className='property_item__icon min' onClick={() => handleRemoveBuyer(buyer)}>-</div>
                              </div>
                            ))}
                            {[
                              ...contacts.map(item => ({ ...item, type: 'contact' })),
                              ...companies.map(item => ({ ...item, type: 'company' }))
                            ]
                              .filter(item => (
                                !selectedBuyers.some(buyer =>
                                  (buyer.type === item.type && buyer.id === item.id)
                                ) && (
                                  (`${item.first_name} ${item.last_name}`.toLowerCase().includes(buyerSearchValue.toLowerCase()) ||
                                    item.company_name?.toLowerCase().includes(buyerSearchValue.toLowerCase()))
                                )
                              ))
                              .map((item, index) => (
                                <div className='contact-item' key={index} onClick={() => handleSelectBuyer(item)}>
                                  <div className='contact_item_value'>
                                    {item.type === 'company' ? item.company_name : `${item.first_name} ${item.last_name}`}
                                  </div>
                                  <div className='contact_item_value'>{item.email}</div>
                                  <div className='contact_item_value'>{item.type}</div>
                                  <div className='property_item__icon plus'>+</div>
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}