import React from 'react'
import { createRoot } from 'react-dom/client'
import './NotificationPopup.css'

export function NotificationPopup({ 
  isOpen, 
  onClose, 
  onAction,
  message,
  cancelButton = {
    text: "Cancel",
    color: "#6c757d"
  },
  actionButton = {
    text: "Confirm",
    color: "#007bff"
  }
}) {
  if (!isOpen) return null

  return (
    <div className="notification-popup-overlay" onClick={onClose}>
      <div className="notification-popup" onClick={e => e.stopPropagation()}>
        <p>{message}</p>
        <div className="notification-buttons">
          <button 
            className="notification-button" 
            onClick={onClose}
            style={{ backgroundColor: cancelButton.color }}
          >
            {cancelButton.text}
          </button>
          <button 
            className="notification-button" 
            onClick={onAction}
            style={{ backgroundColor: actionButton.color }}
          >
            {actionButton.text}
          </button>
        </div>
      </div>
    </div>
  )
}

NotificationPopup.defaultProps = {
  cancelButton: {
    text: "Cancel",
    color: "var(--secondairy-light-medium-gray)"
  },
  actionButton: {
    text: "Confirm",
    color: "var(--green)"
  }
}

export function showNotificationPopup({
  message,
  onClose,
  onAction,
  cancelButton,
  actionButton
}) {
  // Create container for the popup
  const container = document.createElement('div')
  document.body.appendChild(container)
  const root = createRoot(container)

  // Cleanup function to remove the popup
  const cleanup = () => {
    root.unmount()
    document.body.removeChild(container)
  }

  // Wrap the onClose to ensure cleanup
  const handleClose = () => {
    cleanup()
    onClose?.()
  }

  // Wrap the onAction to ensure cleanup
  const handleAction = () => {
    cleanup()
    onAction?.()
  }

  // Render the popup
  root.render(
    <NotificationPopup
      isOpen={true}
      message={message}
      onClose={handleClose}
      onAction={handleAction}
      cancelButton={cancelButton}
      actionButton={actionButton}
    />
  )

  // Return cleanup function in case manual cleanup is needed
  return cleanup
}


