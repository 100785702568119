import React, { useState, useEffect } from 'react'
import './LoadingSpinner.css'
import LoadingHouse from '../../assets/pictos/svg/LoadingHouse'

function LoadingSpinner() {
  return (
    <div className="loading_spinner__container">
      <LoadingHouse color="var(--primary-color-light)" />
      {/* <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div> */}
    </div>
  )
}

export default LoadingSpinner